exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-products-js": () => import("./../../../src/pages/all-products.js" /* webpackChunkName: "component---src-pages-all-products-js" */),
  "component---src-pages-category-transmission-oils-js": () => import("./../../../src/pages/category/transmission-oils.js" /* webpackChunkName: "component---src-pages-category-transmission-oils-js" */),
  "component---src-pages-chemicals-js": () => import("./../../../src/pages/chemicals.js" /* webpackChunkName: "component---src-pages-chemicals-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-consumer-js": () => import("./../../../src/pages/consumer.js" /* webpackChunkName: "component---src-pages-consumer-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-js": () => import("./../../../src/pages/industrial.js" /* webpackChunkName: "component---src-pages-industrial-js" */),
  "component---src-pages-marine-js": () => import("./../../../src/pages/marine.js" /* webpackChunkName: "component---src-pages-marine-js" */),
  "component---src-pages-motorcycle-and-2-stroke-oils-js": () => import("./../../../src/pages/motorcycle-and-2-stroke-oils.js" /* webpackChunkName: "component---src-pages-motorcycle-and-2-stroke-oils-js" */),
  "component---src-pages-packaging-js": () => import("./../../../src/pages/packaging.js" /* webpackChunkName: "component---src-pages-packaging-js" */),
  "component---src-pages-power-generation-js": () => import("./../../../src/pages/power-generation.js" /* webpackChunkName: "component---src-pages-power-generation-js" */),
  "component---src-pages-product-category-engine-oils-heavy-vehicles-js": () => import("./../../../src/pages/product-category/engine-oils-heavy-vehicles.js" /* webpackChunkName: "component---src-pages-product-category-engine-oils-heavy-vehicles-js" */),
  "component---src-pages-product-category-engine-oils-passenger-cars-and-light-transport-vehicles-js": () => import("./../../../src/pages/product-category/engine-oils-passenger-cars-and-light-transport-vehicles.js" /* webpackChunkName: "component---src-pages-product-category-engine-oils-passenger-cars-and-light-transport-vehicles-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-standards-js": () => import("./../../../src/pages/standards.js" /* webpackChunkName: "component---src-pages-standards-js" */),
  "component---src-pages-technical-information-js": () => import("./../../../src/pages/technical-information.js" /* webpackChunkName: "component---src-pages-technical-information-js" */),
  "component---src-pages-transmission-oils-js": () => import("./../../../src/pages/transmission-oils.js" /* webpackChunkName: "component---src-pages-transmission-oils-js" */),
  "component---src-templates-all-products-js": () => import("./../../../src/templates/AllProducts.js" /* webpackChunkName: "component---src-templates-all-products-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/Glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

